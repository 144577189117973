<template>
  <div class="container">
    <div class="banner_box2"></div>
    <div class="solove_box">
      <div  class="solove_box_left">
        <div class="solove_box_left_img"> 
          <LeftNav/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/LeftNav.vue"
export default {
  components:{
    LeftNav
  },
  data() {
    return {

    };
  },
};
</script>

<style  lang="scss" scoped>
.container{
  background-color: #fff;
}
.banner_box2 {
  width: 100%;
  height: 238px;
  background-image: url("../assets/images/140829_3_28.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}
.solove_box{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
  .solove_box_left{
    width: 240px;
    .solove_box_left_img img{
      width: 240px;
      height: 100px;
    }
  }
  .solove_box_right{
    width: 960px;
  }
}
</style>