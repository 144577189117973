<template>
  <div class="container">
    <div class="banner_box"></div>
    <div style="background-color: #fff">
      <div class="about_top_box">
        <img src="../assets/images/Building.png" />
        <h2>云卓全景</h2>
        <p>
          广东云卓全景网络科技有限公司是一家专注在支付领域的ISV服务商，针对线上、线下不同支付场景提供全景解决方案，以及为金融银行体系/第三方支付公司提供商户个性化定制，如：TMS系统、巡检系统、工单系统等,形成「产品+运营」的链条，为商户赋能。
        </p>
      </div>
    </div>
    <div class="about_map">
      <img src="../assets/images/map.png" class="about_map_img" />
      <div class="left_box">
        <div class="left_box_item">
          <div class="image_box">
            <img src="../assets/images/gozhao.png" />
            <p class="word">扫一扫添加公众号</p>
          </div>
          <div class="image_box">
            <img src="../assets/images/zixun.png" />
            <p class="word">微信咨询请扫码</p>
          </div>
        </div>
        <p class="bottom_p" style="margin-top:20px">联系电话：020-85615097</p>
        <p class="bottom_p">商务合作：gdyz-market@plutuspay.com</p>
        <p class="bottom_p">地址：广州市天河区科韵路天河软件园建工路9号4楼436室</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.banner_box {
  width: 100%;
  height: 380px;
  background-image: url("../assets/images/contact1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}
.about_top_box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
}
.about_top_box img {
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px 0;
}
.about_top_box h2 {
  font-size: 28px;
  color: #5a5a5a;
  text-align: center;
  font-weight: normal;
  line-height: 60px;
  text-align: center;
  margin: 0 0 20px 0;
}
.about_top_box p {
  font-size: 16px;
  text-indent: 2em;
  line-height: 40px;
  color: #878787;
  padding-bottom: 80px;
}
.about_map {
  width: 1200px;
  /* height: 507px; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  /* align-items: center; */
}
.about_map .about_map_img {
  width: 574px;
  height: 397px;
}
.left_box {
  display: flex;
  /* border: 1px solid red; */
  margin-left: 50px;
  flex-direction: column;
}
.left_box .image_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.left_box .image_box:nth-child(1) {
  margin-right: 80px;
}
.left_box_item {
  display: flex;
  // height: 100%;
  // margin-bottom: 30px!important;
}
.left_box .bottom_p {
  color: #5a5a5a;
  font-size: 14px;
  line-height: 40px;
}
.word {
  /* margin-top: 10px; */
  text-align: center;
  font-size: 16px;
  color: #000;
}
</style>