import http from '../http/request'
//登录
export async function login(parm) {
    return await http.login("/api/user/login", parm)
}
//获取菜单数据
export async function getMenuList() {
    return await http.get("/api/sysUser/getPermissionList", null)
}
//获取验证码
export async function getImageApi() {
    return await http.getImage("/api/sysUser/image")
}