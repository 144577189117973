import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//下面代码用于解决 
//Redirected when going from "/departmentList" to "/userList" via a navigation
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    // {
    //   path: '/message',
    //   name: 'message',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/test/Message.vue')
    // },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    // },
    {
        path: '/',
        name: 'home',
        component: Home,
        redirect:"/desktop",
        children: [
            {
                path: '/desktop',
                name: 'desktop',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Desktop.vue')
            },
            {
                path: '/trends',
                name: 'trends',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Trends.vue')
            },
            {
              path: '/solve',
              name: 'solve',
              component: () => import(/* webpackChunkName: "about" */ '@/views/Solve.vue')
            },
            {
              path: '/product',
              name: 'product',
              component: () => import(/* webpackChunkName: "about" */ '@/views/Product.vue')
            },
            {
              path: '/about',
              name: 'about',
              component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
            },
            {
              path: '/login',
              name: 'login',
              // route level code-splitting
              // this generates a separate chunk (about.[hash].js) for this route
              // which is lazy-loaded when the route is visited.
              component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
            },
            // {
            //   path: '/goodCategory',
            //   name: 'goodCategory',
            //   // route level code-splitting
            //   // this generates a separate chunk (about.[hash].js) for this route
            //   // which is lazy-loaded when the route is visited.
            //   component: () => import(/* webpackChunkName: "about" */ '@/views/goods/goodsCategory/goodsCategoryList.vue')
            // },
            // {
            //   path: '/goodsBrand',
            //   name: 'goodsBrand',
            //   // route level code-splitting
            //   // this generates a separate chunk (about.[hash].js) for this route
            //   // which is lazy-loaded when the route is visited.
            //   component: () => import(/* webpackChunkName: "about" */ '@/views/goods/goodsBrand/goodsBrandList.vue')
            // },
            // {
            //   path: '/systemCode',
            //   name: 'systemCode',
            //   // route level code-splitting
            //   // this generates a separate chunk (about.[hash].js) for this route
            //   // which is lazy-loaded when the route is visited.
            //   component: () => import(/* webpackChunkName: "about" */ '@/views/system/config/code.vue')
            // },
            // {
            //   path: '/document',
            //   name: 'document',
            //   // route level code-splitting
            //   // this generates a separate chunk (about.[hash].js) for this route
            //   // which is lazy-loaded when the route is visited.
            //   component: () => import(/* webpackChunkName: "about" */ '@/views/system/config/systemDocument.vue')
            // }
        ]
    },
    // {
    //     path: '/dialog',
    //     name: 'dialog',
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/test/dialog.vue')
    // },
    // {
    //     path: '*',
    //     name: '/404',
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/404.vue')
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router