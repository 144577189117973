<template>
    <div class="leftnav_box">
        <el-tabs tab-position="left" style="width: 1200px;" v-model="activeTab" @tab-click="clickTab">
            <el-tab-pane label="互联网支付行业解决方案" style="width: 240px;" disabled name="6"></el-tab-pane>
            <el-tab-pane label="代收代付" style="width: 240px;" name="0">
            <CollecTion/>
            </el-tab-pane>
            <el-tab-pane label="在线支付" style="width: 240px;" name="1">
            <OnLine/>
            </el-tab-pane>
            <el-tab-pane label="新能源智慧充电解决方案" style="width: 240px;" disabled name="3"></el-tab-pane>
            <el-tab-pane label="住宅区、高校区充电解决方案" style="width: 240px;" name="4">
            <Charge/>
            </el-tab-pane>
            <el-tab-pane label="充电平台运营管理解决方案" style="width: 240px;" name="5">
            <Manage/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import CollecTion from "./Tabs/Collection.vue"
import OnLine from "./Tabs/Online.vue"
import Charge from "./Tabs/Charge.vue"
import Manage from "./Tabs/Manage.vue"
export default {
    components:{
        CollecTion,
        OnLine,
        Charge,
        Manage
    },
    data() {
        return {
            activeTab: "0"
        }
    },
    methods: {
        clickTab(e) {
            this.activeTab = e.name
        }
    }
}
</script>
<style lang="scss" scoped>
.leftnav_box {
    width: 240px;
    background-color: #fff;
}

.leftnav_box_one {
    width: 240px;
    height: 60px;
    font-weight: 500;
    color: #444444;
    line-height: 60px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 1px solid #dedede;
}

.leftnav_box_one:hover {
    color: #ff6200 !important;
}

.leftnav_box_two {
    width: 240px;
    height: 60px;
    font-weight: 500;
    cursor: pointer;
    color: #444444;
    line-height: 60px;
    font-size: 16px;
}

.leftnav_box_two:hover {
    color: #ff6200 !important;
}

.active {
    color: #ff6200 !important;
}

::v-deep .el-tabs__item {
    width: 240px;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
}

::v-deep .el-tabs__active-bar {
    background-color: #ff6200 !important;
}

::v-deep .el-tabs__item.is-active {
    color: #ff6200 !important;

}

::v-deep .el-tabs__item:hover {
    color: #ff6200 !important;
}

::v-deep .el-tabs__item.is-disabled {
    color: #000 !important;
    font-weight: 600;
    font-size: 16px;
}
</style>