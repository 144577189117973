<template>
  <div class="container">
    <div class="login_box">
      <div class="center_box">
        <div class="top_box">智慧运营中心</div>
        <div class="login_content">
          <i></i>
          <input type="text" placeholder="请输入用户名" />
        </div>
        <div class="login_contentt">
          <i></i>
          <input type="text" placeholder="请输入密码" />
        </div>
        <div class="login_btn">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  lang="scss" scoped>
.container {
  /* border: 1px solid red; */
  overflow: hidden;
}
.login_box {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/images/BG_proc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.center_box {
  width: 400px;
  height: 460px;
  background: #fff;
  border-radius: 10px;
  margin: 0px auto;
  z-index: 999;
  margin-top: 10%;
  border-right: 1px solid #e2e2e3;
  border-bottom: 2px solid #797e86;
  border-left: 1px solid #e2e2e3;
}
.top_box {
  width: 100%;
  background: rgb(94, 105, 121);
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  padding: 35px 0px;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}
.center_box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.center_box .login_content i {
  position: absolute;
  width: 40px;
  height: 40px;
  background: url("../assets/images/username.png") no-repeat center center;
  left: 5px;
  top: 2px;
}
.center_box .login_contentt i {
  position: absolute;
  width: 40px;
  height: 40px;
  background: url("../assets/images/password.png") no-repeat center center;
  left: 5px;
  top: 2px;
}
.login_contentt {
  /* width: 300px; */
  height: 40px;
  /* margin: 0px 0px 30px 22px; */
  position: relative;
  margin-top: 40px;
}
.login_content {
  /* width: 300px; */
  height: 40px;
  /* margin: 0px 0px 30px 22px; */
  position: relative;
  margin-top: 50px;
}
input {
  width: 260px;
  height: 42px;
  border: 1px solid;
  border-color: rgba(70, 124, 212, 0.2);
  padding: 0 42px 0 50px;
  font-family: 微软雅黑;
  color: #353535;
  border-radius: 2px;
  outline: none;
}
.login_btn {
  width: 352px;
  height: 42px;
  line-height: 42px;
  /* margin: 36px 0px 0px 20px; */
  text-align: center;
  text-decoration: none;
  color: #fff;
  background: #008cf3;
    margin-top: 40px;
  border-radius: 2px;
  font-family: 微软雅黑;
  cursor: pointer;
}
</style>