<template>
  <div>
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <div class="info_box">
          <p style="margin-bottom:10px">
            Copyright © Plutus Internet Finance Technology Co.,ltd All Rights
            Reserved
          </p>
          <p>
            武汉百鸟信息科技有限公司 版权所有 服务热线：020-85615097
            <span style="color: dodgerblue;">鄂ICP备2020022644号</span>
          </p>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  },
  computed: {},
  data() {
    return {
      date: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;
  background-color: #f6f6f6;
}
.el-header {
  padding: 0;
  margin: 0;
  height: 81px !important;
  z-index: 999;
}
.el-main {
  padding: 0;
  margin: 0;
     padding-bottom: 100px;
}
.el-footer {
  padding: 0;
  margin: 0;
   display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
    width: 100vw;
  position: fixed;
  bottom: 0;
  background: #f8f8f8;
  border-top: 1px solid #ddd;
  z-index: 999;
  // position: fixed;
  // bottom: 0;
}
.info_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info_box p {
  margin: 0;
  padding: 0;
  color: #868e93;
  font-size: 12px;
}
</style>