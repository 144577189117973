<template>
  <div class="container">
    <div class="swiper_box">
     <el-carousel  height="400px">
     <el-carousel-item v-for="(item,index) in swiperList" :key="index">
      <img :src="item.image"/>
    </el-carousel-item>
    </el-carousel>
    </div>
    <div class="product1">
      <div class="product1_son">
        <div class="product1_con_left"></div>
        <div class="product1_con_right">
          <h6>简单收付  快乐商家</h6>
          <p>
            <a href="https://www.ysepay.com/channels/merservice_product_index.jsp?merpar=proser_li_01">代收代付</a>
            /
            <a href="https://www.ysepay.com/channels/merservice_product_index.jsp?merpar=proser_li_02">在线支付</a>
            /
            <a href="https://www.ysepay.com/channels/merservice_product_index.jsp?merpar=proser_li_03">POS收单</a>
            /
            <a href="https://www.ysepay.com/channels/merservice_product_index.jsp?merpar=proser_li_04">手机支付</a>
          </p>
          <div class="product_1_h1">
            <a href="https://www.ysepay.com/channels/merservice_product_index.jsp?merpar=proser_li_01">互联网业务在线申请  >></a>
          </div>
          <div class="product1_con_a">
            <a href="https://www.ysepay.com/channels/merservice_index.jsp">更多详情>></a>
          </div>
        </div>
      </div>
    </div>
    <div class="product2">
      <div class="product2_con">
      <div class="product2_con_left">
        <h6>资金安全  费率合理</h6>
        <p>独立自主研发平台</p>
        <p>银联合作金融级资金清算</p>
        <p>灵活结算</p>
        <p>T+1到账</p>
        <p class="product2_con_a">
          <a href="https://www.ysepay.com/channels/productCore_index.jsp">我要装机>></a>
        </p>
      </div>
      <div class="product2_con_right"></div>
    </div>
    </div>
    <!-- <div class="bg_box"></div> -->
  </div>
</template>

<script>
export default {
 data(){
    return{
    swiperList:[
      { id: 0, image: require("../assets/images/banner1.png") },
      { id: 1, image: require("../assets/images/banner2.png") },
      { id: 3, image: require("../assets/images/banner3.png") },
    ]
    }
 }
}
</script>

<style  lang="scss" scoped>
.swiper_box{
    width: 100%;
    height: 400px;
    /* border: 1px solid red; */
}
.swiper_box img{
    height: 100%;
    width: 100%;
}
.bg_box{
    width: 1002px;
    height: 870px;
    background-image: url("../assets/images/bj.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto!important;
}
.product1{
  height: 554px;
  background-image: url("../assets/images/choose.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
}
.product1_son{
  width: 1024px;
  height: 554px;
  // border: 1px solid red;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.product1_con_left{
  width: 600px;
    height: 254px;
}
.product1_con_right{
  width: 424px;
   height: 254px;
   box-sizing: border-box;
   padding-top: 30px;
}
.product_1_h1{
  display: flex;
 align-items: center;
 box-sizing: border-box;
 padding-left: 12px;
 margin-top: 30px;
 margin-bottom: 18px;
  height: 39px;
    width: 248px;
    background-image: url("../assets/images/product_first_bgimg.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.product_1_h1 :hover{
  height: 39px;
    width: 248px;
  background-image: url("../assets/images/product_first_bgimg_c.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 39px;
}
.product1_con_right h6{
  font-size: 38px;
    color: rgb(89, 87, 97);
    padding: 0;
    margin: 0;
    font-family: 'Î¢ÈíÑÅºÚ';
    font-weight: normal;
}
.product1_con_right p{
  margin-top: 2px;
}
.product1_con_right p a{
  font-size: 16px;
    color: rgb(89, 88, 97);
    text-decoration:none;
}
.product_1_h1 a{
  font-size: 18px;
 color: #fff;
 text-decoration: none;
}
 .product1_con_a a {
    font-size: 14px;
    color: rgb(0,160,233);
}

.product2{
  height: 554px;
  background-image: url("../assets/images/safe.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.product2_con{
  width: 1024px;
  display: flex;
  margin: 0 auto;
  height: 554px;
  align-items: center;
}
.product2_con  .product2_con_left{
    width: 500px;
    height: 254px;
}
.product2_con  .product2_con_right{
  width: 524px;
    height: 254px;
}
.product2_con_left h6{
  font-size: 38px;
    color: rgb(89, 87, 97);
    padding: 0;
    margin: 0;
    font-family: 'Î¢ÈíÑÅºÚ';
    font-weight: normal;
    margin-top: 10px;
}
.product2_con_left p{
  font-size: 16px;
    color: rgb(89, 88, 97);
    margin-top: 5px;
}
.product2_con_a{
padding-top: 10px;
}
.product2_con_a a{
  font-size: 14px;
    color: rgb(0,160,233);
    text-decoration: none;
}
</style>