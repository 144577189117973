<template>
  <div class="container">
    <div class="banner_box3"></div>
    <div class="latest_box">
      <div class="latest_box_title">
        <p>云卓最新动态</p>
        <p>COMPANY UPDATE</p>
      </div>
      <div
        class="latest_box_item"
        v-for="(item, index) in latestList"
        :key="index"
      >
        <img :src="item.image" />
        <div class="latest_box_word">
          <p>{{ item.title }}</p>
          <p>{{ item.createTime }}</p>
        </div>
      </div>
      <div class="pagination_box">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      latestList: [
        {
          id: 0,
          title: "全景分账一键解决商户难题",
          createTime: "2020-09-21",
          image: require("../assets/images/23.png"),
        },
        {
          id: 1,
          title: "微信支付宝预授权",
          createTime: "2020-09-21",
          image: require("../assets/images/22.png"),
        },
        {
          id: 2,
          title: "重磅！电子专票来了！！",
          createTime: "2020-09-21",
          image: require("../assets/images/21.png"),
        },
        {
          id: 3,
          title: "刷脸支付 | 时代轻松实现“支付即营销”",
          createTime: "2020-09-21",
          image: require("../assets/images/20.png"),
        },
        {
          id: 4,
          title: "一键节省财务80%的时间 | 地产行业批量开票小工具",
          createTime: "2020-09-21",
          image: require("../assets/images/19.png"),
        },
      ],
    };
  },
};
</script>

<style  lang="scss" scoped>
.banner_box3 {
  width: 100%;
  height: 380px;
  background-image: url("../assets/images/service-banner1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}
.latest_box {
  width: 1026px;
  height: 100%;
  background: #fff;
  box-shadow: 0 3px 6px 0 #f4f7fc;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: border-box;
}
.latest_box_title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}
.latest_box_title p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  color: #ff6200;
  line-height: 20px;
}
.latest_box_title p:nth-child(2) {
  color: #c6c6c6;
  line-height: 20px;
}
.latest_box_item {
  width: 846px;
  /* height: 142px; */
  border-bottom: 1px dashed #dedede;
  padding-bottom: 40px;
  /* border: 1px solid red; */
}
.latest_box_item {
  margin: 0px 90px 30px;
  display: flex;
}
.latest_box_item p:nth-child(1) {
  font-size: 20px;
  color: #111;
  /* padding: 20px 0px; */
  height: 80px;
}
.latest_box_item p:nth-child(2) {
  color: #ccc;
  font-size: 14px;
}
.latest_box_word {
  margin-left: 30px;
}
.pagination_box{
 /* border: 1px solid red;  */
 width: 100%;
 height: 60px;  
 display: flex;
 justify-content: right;
 padding-right: 80px;
 box-sizing: border-box;
}

</style>