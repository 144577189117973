<template>
  <div class="container">
    <div class="banner_box1"></div>
    <div class="product_box">
      <img src="../assets/images/1.png" />
      <img src="../assets/images/2.png" />
      <img src="../assets/images/3.png" />
      <img src="../assets/images/4.png" />
      <img src="../assets/images/5.png" />
      <img src="../assets/images/6.png" />
      <img src="../assets/images/7.png" />
      <img src="../assets/images/9.png" />
      <img src="../assets/images/10.png" />
      <img src="../assets/images/11.png" />
      <img src="../assets/images/12.png" />
      <img src="../assets/images/13.png" />
      <img src="../assets/images/14.png" />
      <img src="../assets/images/15.png" />
      <img src="../assets/images/16.png" />
      <img src="../assets/images/17.png" />
      <img src="../assets/images/18.png" />
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style  lang="scss" scoped>
.banner_box1 {
  width: 100%;
  height: 380px;
  background-image: url("../assets/images/product-banner11.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}
.product_box {
  width: 1022px;
  height: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.product_box img {
  width: 100%;
  height: 100%;
}
</style>