<template>
  <div class="container">
    <div class="head_box">
      <img src="../assets/images/logo.png" />
      <el-menu
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#fff"
        text-color="#000"
        active-text-color="#ff6200"
      >
        <el-menu-item
          v-for="(item, index) in NavList"
          :key="index"
          :index="item.path"
          @click="clickMenu(item)"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      NavList: [
        {
          id: 0,
          name: "首页",
          path: "/desktop",
        },
        {
          id: 2,
          name: "解决方案",
          path: "/solve",
        },
        {
          id: 1,
          name: "动态",
          path: "/trends",
        },
        {
          id: 3,
          name: "产品",
          path: "/product",
        },
        {
          id: 4,
          name: "关于我们",
           path: '/about',
        },
        {
          id: 5,
          name: "平台登录",
          path: '/login',
        },
      ],
    };
  },
  watch:{
    $route: {
      handler(val) {
      console.log(val,"22")

      },
      immediate: true,
    },
  },
  methods: {
    clickMenu(item) {
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.head_box {
  width: 1060px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
}
.head_box img {
  width: 215px;
  height: 80px;
  margin-right: 200px;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  border: none;
  background-color: none !important;
}
::v-deep .el-menu--horizontal > .el-menu-item:hover {
  border: none;
  background-color: none;
}
::v-deep .el-menu-item:hover {
  outline: 0;
  background-color: #fff !important;
  color: #7C8590!important;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border: none;
}
</style>